<template>
  <!-- height:629px; -->

  <div
    style="background-color: #fff;border-radius: 6px;margin-top:17px;box-shadow: 0 2px 12px 0 #ccc;"
  >
    <div class="monitor-top flex-j-b">
      <div class="f20 bd color-a">交易</div>
    </div>
    <div style="padding:15px 24px"  v-for="item in list" :key="item.id">
      <div class="flex-jb-al cur"  @click="$router.push({path:'/trade',query:{type:2}})">
        <div>
          <span class="btn">求购</span>
        </div>
        <div ><img src="../../assets/images/go.png" alt=""></div>
      </div>
      <div class="mt-10 f12"> <span >更新时间：{{item.update_time}}</span></div>
      <div class="f14 color-0 mt-10">{{item.linkman}}想求购{{item.type==1?'专利':'商标'}}，{{item.type==1?'专利':'商标'}}状态为{{item.product_status}}，
        {{item.product_type}}行业。
      </div>
    </div>
    <!-- <div style="padding:24px">
      <div class="flex-jb-al">
        <div>
          <span class="btn tr">转让</span>
        </div>
        <div class="cur" @click="$router.push('/trade')"><img src="../../assets/images/go.png" alt=""></div>
      </div>
       <div class="mt-10"> <span >更新时间：2021-03-04 14:04:55</span></div>
      <div class="f14 color-0 mt-10">下证也可以看看但是要2017年以后的互联网系统医药消毒相关茶农，最好发明，状态不限</div>
    </div> -->
  </div>
</template>
<script>
export default {
  created(){
    this.getlist()
  },
  data(){
    return{
      list:[],
    }
  },
  methods:{
    getlist(){
      this.$request.post('/client/transaction/buyListData',{page:1,limit:4}).then(res=>{
        if(res.data.code==200){
          this.list=res.data.data
        }
      })
    }
  }
}
</script>

<style scoped>
.monitor-top {
  padding: 24px;
  border-bottom: 1px solid #ccc;
}
.btn{
display: inline-block;
width: 40px;
height: 24px;
background: #ebf1ff;
border: 1px solid #2962ff;
border-radius: 4px;
font-size: 12px;
color: #2962ff;
text-align: center;
line-height: 24px;
margin-right: 27px;
}
.btn.tr{
background: #fff4ea;
border: 1px solid #ea6b03;
color:#ea6b03;
}
</style>
