<template>
  <div class="upper-r">
    <div class="monitor-top flex-j-b">
      <div class="f20 bd color-a">年费监控</div>
      <div class="flex-al">
        <span class="f14" style="margin-right: 10px; color: #F40F2F;">您有{{mondata.num||0}}件年费即将到期</span>
        <!-- <img src="../../assets/images/go.png" alt> -->
      </div>
    </div>
    <div style="padding: 30px 66px"  :class="{'mt-30':$store.state.active=='/monitoring'}">
      <div>
        <div class="time mt-20">
          <div class="time-item pr">
            <div class="pa time-item-top">{{mondata.days}}</div>
            <div>|</div>
          </div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="time-item pr">
            <div class="pa time-item-top">30天</div>
            <div>|</div>
          </div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="time-item pr">
            <div class="pa time-item-top">15天</div>
            <div>|</div>
          </div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="time-item pr">
            <div class="pa time-item-top">7天</div>
            <div>|</div>
          </div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="ds"></div>
          <div class="time-item pr">
            <div class="pa time-item-top">{{getTime()}}</div>
            <div>|</div>
          </div>
        </div>
        <div class="mt-20 pr">
          <div class="pro-under pa"></div>
          <div :style="{width:(1-mondata.progress_bar)*100+'%'}" class="pro-up pa">
            <div class="pa indicator">
              <img src="../../assets/images/indicator.png" alt>
              <div class="f11 color-0 pa indicator-con">{{mondata.num}}件即将到期</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="$store.state.active!='/monitoring'" class="monitor-btn cur" @click="$router.push('/monitoring')">年费监控</div>
    </div>
  </div>
</template>
<script>
export default {
  created(){
  this.monitoring()
  },
  data(){
    return{
    mondata:{}
    }
  },
  methods:{
    monitoring(){
      this.$request.get('/client/index/monitoring').then(res=>{
        if(res.data.code==200){
          this.mondata=res.data.data
        }
      })
    },
    getTime(){
      let year=new Date().getFullYear()
      let mon=new Date().getMonth()+1
      let date=new Date().getDate()
        return  year+'.'+mon+'.'+date
    }
   
  }
}
</script>

<style scoped>
.upper-r {
  height: 307px;
  background-color: #fff;
  border-radius: 10px;
}
.monitor-top {
  padding: 24px;
  border-bottom: 1px solid #ccc;
}
.time {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.time-item {
  text-align: center;
}
.ds {
  width: 1px;
  height: 4px;
  background-color: #000000;
}

.monitor-btn {
  font-size: 16px;
  width: 120px;
  background-color: #2962ff;
  text-align: center;
  color: #fff;
  padding: 4px 0;
  margin: 0 auto;
  border-radius: 20px;
  margin-top:70px;
}
.time-item-top {
  width: 60px;
  top: -22px;
  left: -38px;
}
.pro-under {
  height: 12px;
  background: linear-gradient(270deg, #ff6a6a 0%, #ffd600);
  border-radius: 44px;
  width: 100%;
}
.pro-up {
  height: 12px;
  /* background-color: #dee4f2;
    background-color:rgba(222, 228, 242, .4); */
  border-radius: 44px;
  top: 0;
  left: 0;
}
.indicator{
 top: -14px;
right: -18px;
}
.indicator-con{
      width: 86px;
    top: 32px;
    right: -35px;
}
</style>
