<template>
    <div class="business">
          <div class="tab flex">

            <span @click="tabClick(index)" :class="patent_type==index? 'active':''" v-for="(item,index) in tabs" :key="index">{{item}}</span>
            
          </div>
          <div class="data flex-j-b">
            <div>
              <h3>{{sumdata['2']}}</h3>
              <p class="f14">撰写中</p>
            </div>
            <div>
              <h3>{{sumdata['3']}}</h3>
              <p class="f14">待提交</p>
            </div>
            <div>
              <h3>{{sumdata['4']}}</h3>
              <p class="f14">待确认</p>
            </div>
            <div>
              <h3>{{sumdata['5']}}</h3>
              <p class="f14">待修改</p>
            </div>
          </div>
        </div>
</template>
<script>
export default {
  data(){
    return{
      tabs:['专利业务','商标业务','高企业务','财税业务'],
      patent_type:0,
      sumdata:{}
    }
    
  },
  created(){
   this.caseStatistics()
  },
  methods:{
    tabClick(index){
      this.patent_type=index
      this.caseStatistics()
    },
    caseStatistics(){
      let patent_type=this.patent_type==0||this.patent_type==1?this.patent_type+1:this.patent_type+2
      this.$request.get('/client/index/businessStats',{params:{patent_type}}).then(
        res=>{
          this.sumdata=res.data.data
        }
      )
    }
  }
}
</script>

<style scoped>
.business {
  background-color: #fff;
  border-radius: 10px;
  height: 186px;
  overflow: hidden;
}
.tab {
  width: 384px;
  margin: 24px 0  0 24px;
}
.tab span {
  font-size: 14px;
  color: #444444;
  width: 25%;
  padding: 6px 0;
  text-align: center;
  background-color: rgb(234, 241, 255);
  cursor: pointer;
}
.tab span {
  font-size: 16px;
}
.tab span:first-child {
  border-radius: 40px 0 0 40px;
}
.tab span:last-child {
  border-radius: 0 40px 40px 0;
}
.tab .active {
  background-color: #2962ff;
  color: #fff;
}
.business .data {
  margin-top: 40px;
  max-width: 650px;
  padding:0 30px;
}
.business .data > div {
  margin-left: 20px;
  text-align: center;
}
</style>
